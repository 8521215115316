<template>
<v-container>
  <v-row>
    <v-col cols="12">
      <v-card class="v-card--outer">
        <div class="py-12 mb-6" style="background:linear-gradient(45deg, rgba(236,190,77,0.6), rgba(158,54,126,0.9));">
          <p class="d-block text-h1 text-center font-weight-regular">January 2023</p>
          <p class="d-block text-h1 text-center">Updates</p>
        </div>
        <v-card class="v-card--inner">
          <v-card-title>Custom Vocabulary Updates</v-card-title>
          <v-card-text>
            <p>
              When adding or downgrading custom vocabulary, you can now choose to have them put into your lessons.
              This is great if you want to add some words now but plan to study them later.
            </p>
            <p>When adding or editing a word, it's now possible to specify the JLPT level.
              Words added from the dictionary will have the JLPT level set automatically.
              Custom vocab items can now be <strong>archived</strong> and <strong>prioritized</strong> (=have them appear first in lessons), too.</p>
            <p>When adding words from the dictionary through the <code>+ Add</code> button,
              you can now choose which SRS stage the item should be put into.
              In addition there's now an indication whether or not a word has already been added,
              so adding duplicate words by mistake is no longer a thing.
            </p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Browse Tool Updates</v-card-title>
          <v-card-text>
            <p>
              The vocabulary browser received several new filters,
              such as <strong>JLPT level</strong> and <strong>SRS stage</strong>.
              Now you're able to see exactly which words you've learned are in each level!
            </p>
            <p>
              Archived items are now <span class="disabled">grayed out</span> to make them easily recognizable.
            </p>
            <p>Your browsing preferences are now saved locally, too.</p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Enhancements</v-card-title>
          <v-card-text>
            <ul>
              <li>
                Custom vocabulary: JP context sentences can now have characters other than Kanji, Hiragana and Katakana.
              </li>
              <li>Added an "I don't know" button in reviews (=question mark left of input field)</li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Optimizations</v-card-title>
          <v-card-text>
            <ul>
              <li>
                We've upgraded our database server and doubled the RAM - again!
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Bugfixes</v-card-title>
          <v-card-text>
            <ul>
              <li>Theme customization tool: Fixed a bug that prevented users from changing pitch accent colors (except 平板).</li>
              <li>Reviews: Fixed a bug that kept Furigana from being revealed after submitting an answer, even with the corresponding setting enabled.</li>
              <li>Self Study: Fixed a bug that prevented certain preferences from being saved.</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="v-card--outer">
        <div class="py-12 mb-6" style="background:linear-gradient(45deg, rgba(236,190,77,0.6), rgba(158,54,126,0.9));">
          <p class="d-block text-h1 text-center font-weight-regular">Q4 2022</p>
          <p class="d-block text-h1 text-center">Updates</p>
        </div>
        <v-card class="v-card--inner">
          <v-card-title>Issue and Feature Request Tracker</v-card-title>
          <v-card-text>
            <p>
              Torii SRS' issue and feature request tracker for the new web app is now publicly available.
            </p>
            <p>
              Everyone can now browse requested features, check the known bugs and see what's on the roadmap - no signup required.
              Submitting new issues and feature requests requires you to sign up for a free GitHub account.
            </p>
            <p>
              This is completely optional, of course.
              It is still possible to contact us via the contact form or email.
            </p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Release Notes Page</v-card-title>
          <v-card-text>
            <p>
              If you'd like to learn what's new in the latest update of the Torii SRS web app,
              there's now finally a place for that.
            </p>
            <p>
              Starting from September 2022, all the latest and greatest features,
              enhancements and bug fixes will be posted here.
            </p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Pitch Accent Colors</v-card-title>
          <v-card-text>
            <p>
              Whether you're a visual learner or you simply enjoy a colorful learning experience,
              you can now benefit from individually colored pitch accent patterns.
              You're free to adjust colors to your liking!
            </p>
            <p>
              You can enable and edit Pitch Accent Pattern Colors in the
              <v-icon>{{ $store.state.navDrawerItems.appearance.icon }}</v-icon> theme customization tool.
            </p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Lesson Batch Sizing</v-card-title>
          <v-card-text>
            <p>
              It is now possible to adjust the number of new lessons introduced before the quiz
              to any value between 3 and 10.
            </p>
            <p>
              <v-icon>{{ $store.state.navDrawerItems.settings.icon }}</v-icon> Settings >
              Lessons > Batch size
            </p>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Enhancements</v-card-title>
          <v-card-text>
            <ul>
              <li>
                Added a few new colors to the
                <v-icon>{{ $store.state.navDrawerItems.appearance.icon }}</v-icon>
                theme customization tool.
              </li>
              <li>
                Lessons: Added a setting to let context sentence audio autoplay after vocabulary audio
                (like in the desktop app)
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Optimizations</v-card-title>
          <v-card-text>
            <ul>
              <li>
                Settings: Updated and added several descriptions
              </li>
              <li>
                Daily workload setting: Swapped the slider for a text field
              </li>
              <li>
                Reduced navigation drawer item size and spacing
              </li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-title>Bugfixes</v-card-title>
          <v-card-text>
            <ul>
              <li>Browse page: Kanji and kana will no longer wrap onto a new line</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
  <div id="kofiWidget" />
</v-container>
</template>

<script>
export default {
  mounted () {
    const kofiBtn = document.createElement('script')
    kofiBtn.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js'
    kofiBtn.addEventListener('load', () => {
      window.kofiWidgetOverlay.draw('rakantor', {
        type: 'floating-chat',
        'floating-chat.donateButton.text': 'Support Us',
        'floating-chat.donateButton.background-color': '#00b9fe',
        'floating-chat.donateButton.text-color': '#fff'
      }, 'kofiWidget')
    })
    document.body.appendChild(kofiBtn)
  }
}
</script>

<!-- Align Ko-Fi floating widget to the right (default: left) -->
<style lang="sass">
.floatingchat-container-wrap
  left: auto !important
  right: 16px !important
.floating-chat-kofi-popup-iframe
  left: auto !important
  right: 16px !important
</style>

<style scoped lang="sass">
.v-card__text
  font-size: map-deep-get($headings, 'body-1', 'size')
  font-weight: map-deep-get($headings, 'body-1', 'weight')
  line-height: map-deep-get($headings, 'body-1', 'line-height')
  letter-spacing: map-deep-get($headings, 'body-1', 'letter-spacing')
</style>

<style scoped>
.v-card--outer {
  max-width: 1000px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.v-card--inner {
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
