<template>
<v-container fluid fill-height class="pa-0">
  <v-img height="100%" src="@/assets/img/lpwp.jpeg" class="bg-image" />
  <v-toolbar color="rgb(255, 255, 255, 0.8)" class="shrink">
    <router-link to="lp">
      <v-img src="@/assets/img/logo.png" height="46" max-width="60" contain class="ma-3"></v-img>
    </router-link>
    <v-toolbar-title class="text-h5 text-lg-h4 font-weight-medium">
      <span class="primary--text">Torii</span>
      <span class="grey-darken-4--text">SRS</span>
    </v-toolbar-title>
    <v-badge v-if="beta" content="BETA" color="red" class="ml-3">
      <div></div>
    </v-badge>
    <v-spacer></v-spacer>
    <BetaSignupForm v-if="beta" />
  </v-toolbar>
  <v-row no-gutters align="center" justify="center">
    <v-col v-if="showSignIn">
      <v-scroll-x-reverse-transition hide-on-leave>
        <SignIn ref="signin" @showSignUp="showSignIn = false" />
      </v-scroll-x-reverse-transition>
    </v-col>
    <v-col v-else>
      <v-scroll-x-transition hide-on-leave>
        <SignUp ref="signup" @showSignIn="showSignIn = true" />
      </v-scroll-x-transition>
    </v-col>
  </v-row>
  <v-footer app padless dark color="primary">
    <v-row no-gutters justify="center">
      <v-col cols="12" class="text-center">
        <v-btn color="white" text rounded x-small class="my-2" to="faq">
          FAQ
        </v-btn>
        <v-btn color="white" text rounded x-small class="my-2" :href="contactEmailMailto">
          Contact
        </v-btn>
        <TermsOfUse />
        <PrivacyPolicy />
        <CookiesPolicy />
      </v-col>
      <v-col cols="12" class="text-center">
        <v-divider></v-divider>
        <div class="text-caption">&copy; 2018 - {{ new Date().getFullYear() }} Torii SRS</div>
      </v-col>
    </v-row>
  </v-footer>
</v-container>
</template>

<script>
import SignIn from './SignIn.vue'
import SignUp from './SignUp.vue'
import BetaSignupForm from './BetaSignupForm.vue'
import TermsOfUse from '@/components/legal/TermsOfUse.vue'
import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue'
import CookiesPolicy from '@/components/legal/CookiesPolicy.vue'

export default {
  data () {
    return {
      dialog: true,
      showSignIn: false,
      contactEmailMailto: `mailto:${this.$store.state.api.contactEmail}`
    }
  },
  components: {
    SignIn,
    SignUp,
    BetaSignupForm,
    TermsOfUse,
    PrivacyPolicy,
    CookiesPolicy
  },
  computed: {
    beta () {
      return process.env.VUE_APP_BUILD_TARGET === 'BETA'
    }
  },
  created () {
    this.$vuetify.theme.dark = false
  }
}
</script>

<style scoped>
.bg-image {
  background-size:cover;
  left:0;
  top: 0;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  position:absolute;
  width:100%;
  height:100%;
}
.container.fill-height {
  flex-direction: column;
  align-items: initial;
}
.container.fill-height>.row {
  flex: 1 1 auto;
}
</style>
