<template>
<v-container fluid>
  <v-card flat color="transparent" class="mb-1 mx-auto">
    <v-container>
      <v-expansion-panels v-model="expansionPanels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Vocabulary Lists
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-chip-group
              v-model="listSelected"
              mandatory
              column
              active-class="primary--text"
            >
              <v-chip v-for="(item, index) in lists" :key="index" :value="item.id" filter outlined @click="page = 1">
                {{ item.name }}
                <v-icon v-if="item.icon" right class="text--secondary">
                  {{ item.icon }}
                </v-icon>
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Filters
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span class="text-subtitle-1">JLPT Levels</span>
            <v-btn small text color="primary" @click="selectAllJlpt(true)">all</v-btn>
            <v-btn small text color="primary" @click="selectAllJlpt(false)">none</v-btn>
            <v-chip-group
              v-model="jlptSelected"
              multiple
              column
              active-class="primary--text"
              class="mb-6"
            >
              <v-chip v-for="(item, index) in jlptLevels" :key="index" :value="item.id" filter outlined @click="page = 1">
                {{ item.title }}
              </v-chip>
            </v-chip-group>
            <span class="text-subtitle-1">SRS Stages</span>
            <v-btn small text color="primary" @click="selectAllSrsStages(true)">all</v-btn>
            <v-btn small text color="primary" @click="selectAllSrsStages(false)">none</v-btn>
            <v-chip-group
              v-model="typeSelected"
              mandatory
              multiple
              column
              active-class="primary--text"
            >
              <v-chip v-for="(type, index) in types" :key="index" :value="type.id" filter outlined @click="page = 1">
                {{ type.titleFull }}
              </v-chip>
            </v-chip-group>
            <v-chip-group
              v-model="srsStageSelected"
              multiple
              column
              active-class="primary--text"
            >
              <v-chip v-for="(item, index) in srsStages" :key="index" :value="item.id" filter outlined @click="page = 1">
                {{ srsStageName(item.id) }}
              </v-chip>
            </v-chip-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Preferences
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox v-model="cbPitchAccent" label="Pitch Accent"></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="auto" class="grow">
          <WanaKanaTextfield
            :id="searchId"
            v-model="search"
            :active="false"
            indicator
            clearable
            persistent-hint
            hint=" "
            prepend-inner-icon="mdi-magnify"
            label="Search"
          >
            <template v-slot:message>
              <span>* Archived items are </span>
              <span class="disabled">grayed out</span>
            </template>
          </WanaKanaTextfield>
        </v-col>
        <v-col cols="auto" class="shrink">
          <BulkActions :listSelected="listSelected" :selectedItems="selected" @actionProcessed="actionProcessed" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :page.sync="page"
      :search="searchDebounced"
      :expanded.sync="expanded"
      show-expand
      single-expand
      show-select
      multi-sort
      mobile-breakpoint="0"
      :item-class="itemRowBackground"
      :dense="$vuetify.breakpoint.smAndDown"
      :custom-filter="customFilter"
      :footer-props="{ itemsPerPageOptions: itemsPerPageArray, itemsPerPageText: 'words per page:', disablePagination: true, nextIcon: null, prevIcon: null }"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small :class="item.archived ? 'disabled' : ''" @click="viewItem(item)">
          mdi-eye
        </v-icon>
        <v-icon
          v-if="customVocab(item)"
          small
          :class="item.archived ? 'disabled' : ''"
          class="ml-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:[`item.vocab_kana`]="{ item }">
        <span
          :class="pitchAccentClass(item.archived)"
          v-html="vocabReading(item)"
        />
      </template>
      <template v-slot:[`item.meaning`]="{ item }">
        {{ sense(item) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-1">Combined Answered Correct (JP ⇄ EN)</v-list-item-title>
              <v-progress-linear dark rounded height="25"
                :value="
                  item.reviewed_meaning || item.reviewed_reading ?
                  (100 * (item.correct_meaning + item.correct_reading)) / (item.reviewed_meaning + item.reviewed_reading) :
                  0
                "
              >
                <template v-slot="{ value }">
                  <strong v-if="item.reviewed_meaning || item.reviewed_reading">{{ Math.ceil(value) }}%</strong>
                  <span v-else>No Reviews</span>
                </template>
              </v-progress-linear>
              <v-list-item-subtitle>
                <p class="text-left">
                  {{ item.correct_meaning + item.correct_reading }}
                  <span class="float-right"> {{ item.reviewed_meaning + item.reviewed_reading }}</span>
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-1">Meaning Answered Correct (JP → EN)</v-list-item-title>
              <v-progress-linear dark rounded height="25"
                :value="item.reviewed_meaning ? (100 * item.correct_meaning) / item.reviewed_meaning : 0"
              >
                <template v-slot="{ value }">
                  <strong v-if="item.reviewed_meaning">{{ Math.ceil(value) }}%</strong>
                  <span v-else>No Reviews</span>
                </template>
              </v-progress-linear>
              <v-list-item-subtitle>
                <p class="text-left">
                  {{ item.correct_meaning }}
                  <span class="float-right"> {{ item.reviewed_meaning }}</span>
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-1">Reading Answered Correct (EN → JP)</v-list-item-title>
              <v-progress-linear dark rounded height="25"
                :value="item.reviewed_reading ? (100 * item.correct_reading) / item.reviewed_reading : 0"
              >
                <template v-slot="{ value }">
                  <strong v-if="item.reviewed_reading">{{ Math.ceil(value) }}%</strong>
                  <span v-else>No Reviews</span>
                </template>
              </v-progress-linear>
              <v-list-item-subtitle>
                <p class="text-left">
                  {{ item.correct_reading }}
                  <span class="float-right"> {{ item.reviewed_reading }}</span>
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-1">Reading Answered Correct (JP → JP)</v-list-item-title>
              <v-progress-linear dark rounded height="25"
                :value="item.reviewed_jptojp ? (100 * item.correct_jptojp) / item.reviewed_jptojp : 0"
              >
                <template v-slot="{ value }">
                  <strong v-if="item.reviewed_jptojp">{{ Math.ceil(value) }}%</strong>
                  <span v-else>No Reviews</span>
                </template>
              </v-progress-linear>
              <v-list-item-subtitle>
                <p class="text-left">
                  {{ item.correct_jptojp }}
                  <span class="float-right"> {{ item.reviewed_jptojp }}</span>
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-row no-gutters>
              <v-col cols="12" md="6" lg="4">
                <v-list-item-content>
              <v-list-item-title>Meaning SRS Stage: {{ srsStageName(item.streak_meaning) }}</v-list-item-title>
              <v-list-item-subtitle>Next Review: {{ item.streak_meaning > 0 ? nextReview(item.due_meaning) : 'Complete lesson first' }}</v-list-item-subtitle>
            </v-list-item-content>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-list-item-content>
              <v-list-item-title>Reading SRS Stage: {{ srsStageName(item.streak_reading) }}</v-list-item-title>
              <v-list-item-subtitle>Next Review: {{ item.streak_reading > 0 ? nextReview(item.due_reading) : 'Complete lesson first' }}</v-list-item-subtitle>
            </v-list-item-content>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-list-item-content>
              <v-list-item-title>Reading SRS Stage: {{ srsStageName(item.streak_jptojp) }}</v-list-item-title>
              <v-list-item-subtitle>Next Review: {{ (item.streak_meaning > 0 && item.streak_jptojp) > 0 ? nextReview(item.due_jptojp) : 'Complete lesson first' }}</v-list-item-subtitle>
            </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        </td>
      </template>
      <template v-slot:[`footer.prepend`]>
        <v-spacer></v-spacer>
        <span>Page</span>
        <v-text-field
          dense
          single-line
          :value="page"
          type="number"
          hide-spin-buttons
          min="1"
          :max="pageCount"
          class="shrink centered-input text-caption"
          @input="page = parseInt($event)"
        ></v-text-field>
        <span class="mr-6">of {{ pageCount }}</span>
      </template>
    </v-data-table>
    <v-pagination v-model="page" :length="pageCount" total-visible="10"></v-pagination>
  </v-card>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xs" max-width="100%" scrollable transition="dialog-bottom-transition">
    <v-card color="backgroundSecondary">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Viewing「{{ view.vocab }}」</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0" style="height: 5000px;">
        <VocabInfoPanel v-if="viewing" mode="single" :vocab="view" />
        <ManualTab v-else editing :item="view" />
      </v-card-text>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import BulkActions from './BulkActions.vue'
import VocabInfoPanel from '@/components/VocabInfoPanel.vue'
import ManualTab from '@/vocab/ManualTab.vue'
import WanaKanaTextfield from '@/components/WanaKanaTextfield.vue'
import { jmdict } from '@/plugins/jmdict.js'
import { pitchaccent } from '@/plugins/pitchaccent.js'

export default {
  components: {
    BulkActions,
    VocabInfoPanel,
    ManualTab,
    WanaKanaTextfield
  },
  props: {
    selListId: String
  },
  data () {
    return {
      searchId: 'browseSearch',
      itemsPerPageArray: [10, 25, 50, 100],
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: 'Actions',
          value: 'actions',
          width: 1,
          sortable: false,
          filterable: false
        },
        {
          text: 'Vocab',
          value: 'vocab',
          width: 1,
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap'
        },
        {
          text: 'Hiragana',
          value: 'vocab_kana',
          width: 1,
          class: 'text-no-wrap',
          cellClass: 'text-no-wrap'
        },
        {
          text: 'Meaning(s)',
          value: 'meaning',
          class: 'text-no-wrap'
        },
        {
          text: 'JLPT N',
          value: 'jlpt_level',
          width: 1,
          filterable: false
        },
        {
          text: '',
          value: 'data-table-expand',
          width: 1
        }
      ],
      selected: [],
      expanded: [],
      search: '',
      searchDebounced: '',
      lists: [
        { id: 'all', name: 'Everything' },
        { id: 'learned', name: 'Learned' },
        { id: 'priority', name: 'Prioritized' },
        { id: 'archived', name: 'Archived' },
        { id: 'custom', name: 'My words', icon: 'mdi-star-half-full' }
      ],
      jlptLevels: [
        { id: 5, title: 'N5' },
        { id: 4, title: 'N4' },
        { id: 3, title: 'N3' },
        { id: 2, title: 'N2' },
        { id: 1, title: 'N1' },
        { id: 0, title: 'Unspecified' }
      ],
      dialog: false,
      view: [],
      viewing: false
    }
  },
  computed: {
    items () {
      let list
      switch (this.listSelected) {
        case 'learned': list = this.$store.getters['vocabulary/learnedVocab']
          break
        case 'custom': list = this.$store.getters['vocabulary/customVocab']
          break
        case 'archived': list = this.$store.getters['vocabulary/archivedVocab']
          break
        case 'priority': list = this.$store.getters['vocabulary/priorityVocab']
          break
        default: list = this.$store.state.vocabulary.vocab
      }

      return list.filter(v =>
        this.jlptSelected.includes(v.jlpt_level) &&
        ((this.typeSelected.includes(1) && this.srsStageSelected.includes(v.streak_meaning)) ||
        (this.typeSelected.includes(2) && this.srsStageSelected.includes(v.streak_reading)) ||
        (this.typeSelected.includes(4) && this.srsStageSelected.includes(v.streak_jptojp)))
      )
    },
    types () {
      return this.$store.state.reviewModes.filter(mode => mode.id !== 3)
    },
    srsStages () {
      return this.$store.state.srsStages
    },
    expansionPanels: {
      get () {
        return this.$store.state.settings.browse.panelsExpanded
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { panelsExpanded: val } })
      }
    },
    listSelected: {
      get () {
        return this.selListId || this.$store.state.settings.browse.vocabListSelected
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { vocabListSelected: val } })
      }
    },
    jlptSelected: {
      get () {
        return this.$store.state.settings.browse.jlptLevels
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { jlptLevels: val } })
      }
    },
    typeSelected: {
      get () {
        return this.$store.state.settings.browse.reviewModes
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { reviewModes: val } })
      }
    },
    srsStageSelected: {
      get () {
        return this.$store.state.settings.browse.srsStages
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { srsStages: val } })
      }
    },
    cbPitchAccent: {
      get () {
        return this.$store.state.settings.browse.pitchAccent
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { browse: { pitchAccent: val } })
      }
    }
  },
  watch: {
    search (val) {
      if (!val) {
        return
      }

      this.filterEntriesDebounced()
    },
    listSelected () {
      this.selected = []
    }
  },
  methods: {
    selectAllJlpt (selectAll) {
      if (selectAll) this.jlptSelected = this.jlptLevels.map(e => e.id)
      else this.jlptSelected = []
    },
    selectAllSrsStages (selectAll) {
      if (selectAll) this.srsStageSelected = this.srsStages.map(e => e.id)
      else this.srsStageSelected = []
    },
    filterEntriesDebounced () {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 300ms
      this._timerId = setTimeout(() => {
        this.searchDebounced = this.search
      }, 300)
    },
    itemRowBackground (item) {
      return item.archived ? 'disabled' : ''
    },
    srsStageName (stageId) {
      return this.$store.getters.srsStageName(stageId)
    },
    vocabReading (vocab) {
      if (vocab.accent) {
        return pitchaccent.generate(vocab.vocab_kana, vocab.accent)
      } else {
        return vocab.vocab_kana
      }
    },
    sense (vocab) {
      if (this.customVocab(vocab)) return vocab.meaning
      else return jmdict.getGlossAsString(vocab)
    },
    nextReview (unixTimestamp) {
      if (unixTimestamp <= this.$date.utc().unix()) {
        return 'Available Now'
      } else {
        const date = this.$date.unix(unixTimestamp).utc().add(this.$store.state.settings.timezone, 'hours')
        return date.format('MMMM DD, YYYY - hh:mm a')
      }
    },
    pitchAccentClass (itemIsArchived) {
      let pClass
      if (itemIsArchived) {
        pClass = 'pitch--plain disabled'
      } else {
        const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
        pClass = this.$store.state.settings.theme[theme].pitchAccentColors.enable
          ? 'pitch--colored'
          : 'pitch--plain'
      }
      return this.cbPitchAccent ? pClass : ''
    },
    viewItem (vocab) {
      this.view = vocab
      this.viewing = true
      this.dialog = true
    },
    editItem (vocab) {
      this.view = vocab
      this.viewing = false
      this.dialog = true
    },
    customVocab (vocab) {
      return vocab.id >= this.$store.state.vocabulary.customVocabStartIndex
    },
    customFilter (value, search, item) {
      this.page = 1
      return search != null &&
        (item.vocab === search ||
        item.vocab_kana === search ||
        (!this.customVocab(item) && this.sense(item).toLowerCase().includes(search)) ||
        (this.customVocab(item) && item.meaning.toLowerCase().includes(search)) ||
        (item.synonyms && item.synonyms.toLowerCase().includes(search)) ||
        (item.readings && item.readings.toLowerCase().includes(search)) ||
        (item.notes && item.notes.toLowerCase().includes(search)))
    },
    actionProcessed () {
      this.selected = []
      this.$store.dispatch('requireReload')
    }
  }
}
</script>

<style scoped>
.centered-input:deep(input) {
  text-align: center;
}
</style>
