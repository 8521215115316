<template>
<v-navigation-drawer app dark
  v-model="drawer"
  height="100%"
  :permanent="!$vuetify.breakpoint.smAndDown"
  :touchless="!$vuetify.breakpoint.smAndDown"
  :mini-variant="mini"
>
  <v-list nav dense>
    <v-list-item two-line :class="mini && 'px-0'">
      <v-list-item-avatar rounded color="white">
        <img :src="image" class="py-1">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ userName }}</v-list-item-title>
        <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="pb-2"></v-divider>
    <v-list-item-group id="navDrawer" dark color="info">
      <v-list-item v-for="(item, i) in staticItems" :key="i" link :to="item.link">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
  <template v-slot:append>
    <v-list nav dense>
      <v-divider class="pb-2"></v-divider>
      <v-list-item link @click="$refs.themeCustomizer.show = true">
        <v-list-item-icon>
          <v-icon>{{ $store.state.navDrawerItems.appearance.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $store.state.navDrawerItems.appearance.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- TODO: finish whenever
      <v-list-item link @click="$refs.langSwitcher.show = true">
        <v-list-item-icon>
          <v-icon>{{ $store.state.navDrawerItems.lang.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $store.state.navDrawerItems.lang.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
      <v-list-item v-show="$vuetify.breakpoint.lgAndUp" link @click="miniVariant = !miniVariant">
        <v-list-item-icon>
          <v-icon v-if="mini">mdi-chevron-right</v-icon>
          <v-icon v-else>mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('navDrawer.collapse') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </template>
  <ThemeCustomizer ref="themeCustomizer" />
  <LanguageSwitcher ref="langSwitcher" />
</v-navigation-drawer>
</template>

<script>
import ThemeCustomizer from '@/components/ThemeCustomizer.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'

export default {
  data () {
    return {
      themeSwitch: this.$vuetify.theme.dark,
      image: require('@/assets/img/logo.png'),
      drawer: false
    }
  },
  components: {
    ThemeCustomizer,
    LanguageSwitcher
  },
  computed: {
    staticItems () {
      return [
        this.$store.state.navDrawerItems.dashboard,
        this.$store.state.navDrawerItems.selfStudy,
        this.$store.state.navDrawerItems.add,
        this.$store.state.navDrawerItems.browse,
        this.$store.state.navDrawerItems.stats,
        this.$store.state.navDrawerItems.help,
        this.$store.state.navDrawerItems.settings
      ]
    },
    userName () {
      return this.$store.state.user.name
    },
    userEmail () {
      return this.$store.state.userEmail
    },
    appVersion () {
      return 'v' + process.env.VUE_APP_VERSION + (process.env.VUE_APP_BUILD_TARGET === 'BETA' ? '-beta' : '')
    },
    online () {
      return this.$store.getters.online
    },
    mini () {
      if (this.$vuetify.breakpoint.smAndDown) return false
      else if (this.$vuetify.breakpoint.mdOnly) return true
      else return this.miniVariant
    },
    miniVariant: {
      get () {
        return this.$store.state.settings.navDrawerMini
      },
      set (state) {
        this.$store.dispatch('settings/updateLocal', { navDrawerMini: state })
      }
    },
    supportColor () {
      if (this.$store.getters.proUser) return ''
      else return 'error'
    }
  },
  methods: {
    toggle () {
      this.drawer = !this.drawer
    }
  }
}
</script>
